<template>
  <section>
    <b-card>
      <b-card-title>
        {{ project.project_name }}
      </b-card-title>
      <hr />
      <b-row>
        <b-col md="6">
          <b-form-group label="Ragione Sociale">
            <b-form-input
              disabled
              :value="project.project_customer_companyname"
            >
            </b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="Partita IVA">
            <b-form-input disabled :value="project.project_customer_vatnumber">
            </b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="Nome Amministratore/Referente">
            <b-form-input disabled :value="project.project_customer_ceo_name">
            </b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="Cognome Amministratore/Referente">
            <b-form-input
              disabled
              :value="project.project_customer_ceo_surname"
            >
            </b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="my-5">
        <b-col md="3"></b-col>
        <b-col md="3">
          <p>Logo Cliente</p>
          <img
            v-if="project.project_customer_logo_path"
            height="100"
            :src="host + project.project_customer_logo_path"
          />

          <p v-else>Nessun File Caricato</p>
        </b-col>
        <b-col md="3">
          <p>Foto Stabilimento</p>
          <img
            v-if="project.project_customer_splashimage_path"
            height="100"
            :src="host + project.project_customer_splashimage_path"
          />
          <p v-else>Nessun File Caricato</p>
        </b-col>
        <b-col md="3"></b-col>
      </b-row>
      <b-row>
        <b-col md="4">
          <b-form-group label="Indirizzo (sede legale)">
            <b-form-input disabled :value="project.project_customer_address">
            </b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="8">
          <b-row>
            <b-col md="3">
              <b-form-group label="Città (sede legale)">
                <b-form-input disabled :value="project.project_customer_city">
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="CAP (sede legale)">
                <b-form-input
                  disabled
                  :value="project.project_customer_zipcode"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Provincia (sede legale)">
                <b-form-input
                  disabled
                  :value="project.project_customer_province"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Nazione (sede legale)">
                <b-form-input
                  disabled
                  :value="project.project_customer_country"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <hr />

      <b-row>
        <b-col md="3"></b-col>
        <b-col md="3">
          <b-form-group label="Sopralluogo effettuato?">
            <b-form-input disabled :value="project.project_siteinspection">
            </b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="3" v-if="project.project_siteinspection == 'Yes'">
          <b-form-group label="Data sopralluogo">
            <b-form-input disabled :value="project.project_siteinspection_date">
            </b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="3"></b-col>
      </b-row>

      <hr />

      <b-row>
        <b-col md="12">
          <b-form-group label="Descrizione attività e storia del cliente">
            <b-form-textarea
              :rows="8"
              disabled
              :value="project.project_customer_about"
            >
            </b-form-textarea>
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group label="Descrizione intervento">
            <b-form-textarea
              :rows="8"
              disabled
              :value="project.project_customer_desc_intervention"
            >
            </b-form-textarea>
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group label="Tipologia di intervento">
            <b-form-textarea
              :rows="8"
              disabled
              :value="project.project_customer_type_intervention"
            >
            </b-form-textarea>
          </b-form-group>
        </b-col>
      </b-row>

      <hr />

      <b-row>
        <b-col md="6">
          <b-form-group label="Nome tecnico incaricato 1">
            <b-form-input disabled :value="project.project_technical_1_name">
            </b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="Cognome tecnico incaricato 1">
            <b-form-input disabled :value="project.project_technical_1_surname">
            </b-form-input>
          </b-form-group>
        </b-col>
      </b-row>

      <hr />

      <b-row>
        <b-col md="6">
          <b-form-group label="Nome tecnico incaricato 2">
            <b-form-input disabled :value="project.project_technical_2_name">
            </b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="Cognome tecnico incaricato 2">
            <b-form-input disabled :value="project.project_technical_2_surname">
            </b-form-input>
          </b-form-group>
        </b-col>
      </b-row>

      <hr />

      <fieldset class="border p-1 bg-light">
        <legend
          class="text-center mx-auto bg-white border border-dark px-2"
          style="width: min-content; white-space: nowrap"
        >
          Sito Intervento
        </legend>
        <b-row class="my-2">
          <b-col md="4">
            <b-form-group label="Indirizzo">
              <b-form-input
                disabled
                :value="project.project_siteinspection_address"
              />
            </b-form-group>
          </b-col>

          <b-col md="8">
            <b-row>
              <b-col md="3">
                <b-form-group label="Città">
                  <b-form-input
                    disabled
                    :value="project.project_siteinspection_city"
                  />
                </b-form-group>
              </b-col>

              <b-col md="3">
                <b-form-group label="CAP">
                  <b-form-input
                    disabled
                    :value="project.project_siteinspection_zipcode"
                  />
                </b-form-group>
              </b-col>

              <b-col md="3">
                <b-form-group label="Provincia">
                  <b-form-input
                    disabled
                    :value="project.project_siteinspection_province"
                  />
                </b-form-group>
              </b-col>

              <b-col md="3">
                <b-form-group label="Nazione">
                  <b-form-input
                    disabled
                    :value="project.project_siteinspection_country"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <hr />

        <b-row class="my-3">
          <b-col md="4">
            <p class="mr-2">Foto 1</p>

            <img
              v-if="project.project_siteinspection_photo_1_path"
              height="100"
              :src="host + project.project_siteinspection_photo_1_path"
            />

            <p v-else>Nessun File Caricato</p>
          </b-col>

          <b-col md="4">
            <p class="mr-2">Foto 2</p>
            <img
              v-if="project.project_siteinspection_photo_2_path"
              height="100"
              :src="host + project.project_siteinspection_photo_2_path"
            />

            <p v-else>Nessun File Caricato</p>
          </b-col>

          <b-col md="4">
            <p class="mr-2">Planimetria</p>
            <img
              v-if="project.project_siteinspection_attachment_path"
              height="100"
              :src="host + project.project_siteinspection_attachment_path"
            />
            <p v-else>Nessun File Caricato</p>
          </b-col>
        </b-row>
      </fieldset>

      <hr />

      <b-row>
        <b-col md="4">
          <b-form-group label="Dettagli Impianto">
            <b-form-textarea
              :rows="8"
              disabled
              :value="project.project_implant_details"
            >
            </b-form-textarea>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group label="Scopo di utilizzo">
            <b-form-input disabled :value="project.project_implant_scope">
            </b-form-input>
          </b-form-group>

          <b-form-group
            label="È prevista attività di manutenzione/riparazione/recupero e riutilizzo o smaltimento di fluido refrigerante o parti di impianto?"
          >
            <b-form-radio-group disabled v-model="project.question_5">
              <b-form-radio name="Sì" value="Yes">Sì</b-form-radio>
              <b-form-radio name="Sì" value="No">No</b-form-radio>
            </b-form-radio-group>
          </b-form-group>

          <b-form-group
            label="È presente una pista in ghiaccio? Es. pista di pattinaggio sul ghiaccio?"
          >
            <b-form-radio-group disabled v-model="project.question_0">
              <b-form-radio name="Sì" value="Yes">Sì</b-form-radio>
              <b-form-radio name="Sì" value="No">No</b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group label="Definizione ruolo utente">
            <b-form-input disabled :value="project.project_user_role">
            </b-form-input>
          </b-form-group>

          <b-form-group label="Si interviene su impianto" for="project_type">
            <b-form-input disabled :value="project.project_type" />
          </b-form-group>
        </b-col>
      </b-row>

      <hr />

      <b-row>
        <b-col md="4">
          <b-form-group label="Categoria di accesso">
            <b-form-input disabled :value="project.project_access_category">
            </b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group label="Allocazione Impianto">
            <b-form-input disabled :value="project.project_plant_allocation">
            </b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group label="Tipologia impianto">
            <b-form-input disabled :value="project.project_plant_type">
            </b-form-input>
          </b-form-group>
        </b-col>
      </b-row>

      <hr />

      <b-row>
        <b-col md="4"> </b-col>
        <b-col md="4">
          <b-form-group label="N° di ambienti refrigerati / celle">
            <b-form-input disabled :value="project.project_refrigerated_rooms">
            </b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="4"> </b-col>
      </b-row>

      <hr />

      <!-- Celle -->

      <b-tabs vertical>
        <b-tab
          v-for="(cell, i) in project.project_refrigerated_rooms_details"
          :key="i"
        >
          <template #title>
            <span ref="checkIcon" class="mr-1" />
            <span>Ambiente Refrigerato/Cella: {{ cell.cell_name }}</span>
          </template>
          <b-row>
            <b-col md="4">
              <b-form-group label="Superficie (m²)">
                <b-form-input
                  disabled
                  :value="cell.cell_surface"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="Volume (m³)">
                <b-form-input disabled :value="cell.cell_volume"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="Temperatura (°C)">
                <b-form-input disabled :value="cell.cell_temperature">
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-row>

          <fieldset class="border p-1 bg-light my-2">
            <legend
              class="bg-white border border-dark px-2"
              style="width: min-content; white-space: nowrap"
            >
              Unità Condensatrice
            </legend>
            <b-row>
              <b-col md="4">
                <b-form-group label="Marca">
                  <b-form-input
                    disabled
                    :value="cell.condenser_brand"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group label="Modello">
                  <b-form-input
                    disabled
                    :value="cell.condenser_model"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group label="Luogo di installazione">
                  <b-form-input
                    disabled
                    :value="cell.condenser_place"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
          </fieldset>

          <fieldset class="border p-1 bg-light my-2">
            <legend
              class="bg-white border border-dark px-2"
              style="width: min-content; white-space: nowrap"
            >
              Scambiatore
            </legend>

            <b-row>
              <b-col md="4">
                <b-form-group label="Marca">
                  <b-form-input
                    disabled
                    :value="cell.exchanger_brand"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group label="Modello">
                  <b-form-input
                    disabled
                    :value="cell.exchanger_model"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group label="Luogo di installazione">
                  <b-form-input
                    disabled
                    :value="cell.exchanger_place"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
          </fieldset>

          <fieldset class="border p-1 bg-light my-2">
            <legend
              class="bg-white border border-dark px-2"
              style="width: min-content; white-space: nowrap"
            >
              Compressore
            </legend>

            <b-row>
              <b-col md="4">
                <b-form-group label="Marca">
                  <b-form-input
                    disabled
                    :value="cell.compressor_brand"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group label="Modello">
                  <b-form-input
                    disabled
                    :value="cell.compressor_model"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group label="Luogo di installazione">
                  <b-form-input
                    disabled
                    :value="cell.compressor_place"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
          </fieldset>

          <fieldset class="border p-1 bg-light my-2">
            <legend
              class="bg-white border border-dark px-2"
              style="width: min-content; white-space: nowrap"
            >
              Evaporatore
            </legend>

            <b-row>
              <b-col md="4">
                <b-form-group label="Marca Evaporatore">
                  <b-form-input
                    disabled
                    :value="cell.evaporator_brand"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group label="Modello Evaporatore">
                  <b-form-input
                    disabled
                    :value="cell.evaporator_model"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group label="Luogo di installazione">
                  <b-form-input
                    disabled
                    :value="cell.evaporator_place"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
          </fieldset>

          <b-row>
            <b-col md="4">
              <b-form-group
                label="Persone che possono accedere all'ambiente refrigerato in contemporanea"
              >
                <b-form-input
                  disabled
                  :value="cell.number_of_user"
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group label="Si trova nel seminterrato?">
                <b-form-radio-group disabled v-model="cell.question_1">
                  <b-form-radio name="Sì" value="Yes">Sì</b-form-radio>
                  <b-form-radio name="No" value="No">No</b-form-radio>
                </b-form-radio-group>
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group
                label="L'ambiente è provvisto di uscite di sicurezza?"
              >
                <b-form-radio-group disabled v-model="cell.question_2">
                  <b-form-radio name="Sì" value="Yes">Sì</b-form-radio>
                  <b-form-radio name="No" value="No">No</b-form-radio>
                </b-form-radio-group>
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group
                :label="'Ci sono aperture per la ventilazione naturale nell\'ambiente?'"
              >
                <b-form-radio-group disabled v-model="cell.question_3">
                  <b-form-radio name="Sì" value="Yes">Sì</b-form-radio>
                  <b-form-radio name="No" value="No">No</b-form-radio>
                </b-form-radio-group>
              </b-form-group>
            </b-col>

            <b-col v-if="project.project_type == 1" md="4">
              <b-form-group
                :label="'Indicare la quantità di refrigerante presente nell\'impianto per l\'ambiente'"
              >
                <b-form-input
                  type="number"
                  v-model="cell.refrigerant_quantity"
                  disabled
                />
              </b-form-group>
            </b-col>

            <b-col v-else-if="project.project_type == 2" md="4">
              <b-form-group
                :label="'Indicare la quantità di refrigerante da progetto per l\'ambiente'"
              >
                <b-form-input
                  type="number"
                  v-model="cell.refrigerant_quantity"
                  disabled
                />
              </b-form-group>
            </b-col>

            <b-col v-else-if="project.project_type == 3" md="4">
              <b-form-group
                :label="'Si conferma che non essendo presente un progetto, la quantità limite da utilizzare è quella teorica: '"
              >
                <b-form-radio-group disabled v-model="cell.question_4">
                  <b-form-radio name="Sì" value="Yes">Sì</b-form-radio>
                  <b-form-radio name="No" value="No">No</b-form-radio>
                </b-form-radio-group>
              </b-form-group>
            </b-col>
          </b-row>
        </b-tab>
      </b-tabs>

      <hr />

      <b-row>
        <b-col md="6">
          <b-form-group
            label="All'interno dell'edificio c'è un seminterrato non asservito da impianto o parte di impianto?"
          >
            <b-form-radio-group disabled v-model="project.question_1">
              <b-form-radio name="Sì" value="Yes">Sì</b-form-radio>
              <b-form-radio name="Sì" value="No">No</b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Gli operatori possono accedere alla cella frigo?"
          >
            <b-form-radio-group disabled v-model="project.question_2">
              <b-form-radio name="Sì" value="Yes">Sì</b-form-radio>
              <b-form-radio name="Sì" value="No">No</b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </b-col>
      </b-row>

      <hr />

      <b-row>
        <b-col md="6">
          <b-form-group label="Tipologia Refrigerante">
            <b-form-input disabled :value="project.refrigerant_type_label">
            </b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Le giunzioni tra le parti di impianto sono saldate?"
          >
            <b-form-radio-group disabled v-model="project.question_3">
              <b-form-radio name="Sì" value="Yes">Sì</b-form-radio>
              <b-form-radio name="Sì" value="No">No</b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </b-col>
      </b-row>

      <hr />

      <b-row>
        <b-col md="6">
          <b-form-group
            label="L'unità interna è montata ad un'altezza inferiore a 1,8m?"
          >
            <b-form-radio-group disabled v-model="project.question_4">
              <b-form-radio name="Sì" value="Yes">Sì</b-form-radio>
              <b-form-radio name="Sì" value="No">No</b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="12">
          <h3>Misure di sicurezza adottate:</h3>
        </b-col>

        <b-col md="12">
          <b-form-group label="Ventilazione">
            <b-form-radio-group disabled v-model="project.security_question_1">
              <b-form-radio name="Sì" value="Yes">Sì</b-form-radio>
              <b-form-radio name="No" value="No">No</b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="Sono presenti aperture perimetrali inferiori e superiori per la diluizione dell'aria?"
          >
            <b-form-radio-group disabled v-model="project.security_question_2">
              <b-form-radio name="Sì" value="Yes">Sì</b-form-radio>
              <b-form-radio name="No" value="No">No</b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group label="È presente un sisema di ventilazione meccanica?">
            <b-form-radio-group disabled v-model="project.security_question_3">
              <b-form-radio name="Sì" value="Yes">Sì</b-form-radio>
              <b-form-radio name="No" value="No">No</b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="Sono presenti valvole di intercettazione di sicurezza?"
          >
            <b-form-radio-group disabled v-model="project.security_question_4">
              <b-form-radio name="Sì" value="Yes">Sì</b-form-radio>
              <b-form-radio name="No" value="No">No</b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="Sono presenti allarmi o dispositivi di sicurezza?"
          >
            <b-form-radio-group disabled v-model="project.security_question_5">
              <b-form-radio name="Sì" value="Yes">Sì</b-form-radio>
              <b-form-radio name="No" value="No">No</b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="La scelta dei materiali da utilizzare tiene conto della destinazione d'uso, delle pressioni di esercizio e dei parametri di resilienza?"
          >
            <b-form-radio-group disabled v-model="project.security_question_6">
              <b-form-radio name="Sì" value="Yes">Sì</b-form-radio>
              <b-form-radio name="No" value="No">No</b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="L'impianto è munito di vaso di espansione e valvole di sicurezza?"
          >
            <b-form-radio-group disabled v-model="project.security_question_7">
              <b-form-radio name="Sì" value="Yes">Sì</b-form-radio>
              <b-form-radio name="No" value="No">No</b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="Le tubazioni e le parti di impianto sono protette da fonti di calore esterne?"
          >
            <b-form-radio-group disabled v-model="project.security_question_8">
              <b-form-radio name="Sì" value="Yes">Sì</b-form-radio>
              <b-form-radio name="No" value="No">No</b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="Le tubazioni e le parti di impianto sono difficilmente raggiungibili dall'operatore?"
          >
            <b-form-radio-group disabled v-model="project.security_question_9">
              <b-form-radio name="Sì" value="Yes">Sì</b-form-radio>
              <b-form-radio name="No" value="No">No</b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="L'impianto è munito di Pressostati e termostati con valori di set?"
          >
            <b-form-radio-group disabled v-model="project.security_question_10">
              <b-form-radio name="Sì" value="Yes">Sì</b-form-radio>
              <b-form-radio name="No" value="No">No</b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="L'impianto è munito di smorzatori e tubi antivibrazioni?"
          >
            <b-form-radio-group disabled v-model="project.security_question_11">
              <b-form-radio name="Sì" value="Yes">Sì</b-form-radio>
              <b-form-radio name="No" value="No">No</b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
  </section>
</template>

<script>
import {
  BCard,
  BCardTitle,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormRadioGroup,
  BFormRadio,
  BRow,
  BCol,
  BTabs,
  BTab,
} from "bootstrap-vue";
import { Requests } from "@/api/requests.js";


export default {
  components: {
    BCard,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormRadioGroup,
    BFormRadio,
    BRow,
    BCol,
    BTabs,
    BTab,
  },

  data: () => ({
    host: BACKEND_BASE_URL,
    project: {},
  }),

  created() {
    this.getProjectBackend();
  },

  methods: {
    async getProjectBackend() {
      let data = new FormData();

      data.append("id_project", this.$route.params.project_id);

      try {
        const response = await Requests.getProjectBackend(data);
        this.project = response[0].account_project_details.payload;
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>
